
/**
 * Collapsible Card Lasagne is great at displaying more information when expanded
 *
 */
export default {
  name: "CollapsibleCardLasagne",
  props: {
    /** The title displayed on left-hand side of the header */
    title: {
      type: String,
      default: "Title",
    },
    /** The icon optionally displayed next to the title */
    titleIcon: {
      type: String,
      default: null,
    },
    /** The icon tooltip optionally displayed above the icon title */
    titleIconTooltip: {
      type: String,
      default: null,
    },
    /** The background color of the header. It accepts only bootstrap background classes */
    headerBackground: {
      type: String,
      default: "bg-white",
    },
    /** The text color of the header. It accepts only bootstrap text color classes */
    headerTextColor: {
      type: String,
      default: "text-dark",
    },
    /** The default visibility of the collapsible body */
    expandedByDefault: {
      type: Boolean,
      default: true,
    },
    /** The visibility of the chevron on the right-hand side of the header. Default is true
     *  If displayChevron === true then the card won't be collapsible
     */
    displayChevron: {
      type: Boolean,
      default: true,
    },
    /** The visibility of the default save button. Default is false */
    displaySaveButton: {
      type: Boolean,
      default: false,
    },
    /** Toggles the disabled state of the save button */
    saveButtonDisabled: {
      type: Boolean,
      default: false,
    },
    expandSectionToggle: {
      type: Boolean,
      default: false,
    },
    collapseSectionToggle: {
      type: Boolean,
      default: false,
    },
    /** The variant used by the save button. This must be one of bootstraps variants */
    saveButtonColor: {
      type: String,
      default: "primary",
    },
    /** Toggles the disabled state of the save button */
    saveButtonText: {
      type: String,
      default: "All Good",
    },
    /** If false the header separator will be hidden */
    showSeparator: {
      type: Boolean,
      default: true,
    },
    /**
     * Border property of collapsible card
     */
    border: {
      type: String,
      default: "border-0",
    },
    /**
     * Box-shadow of collapsible card
     *
     * Options: undefined|shadow-none|shadow-sm|shadow|shadow-lg
     */
    shadow: {
      type: String,
      default: "shadow-sm",
    },
    /**
     * Whether collapsible card is in loading state
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * Force the user to click on collapse icon
     */
    disableHeaderClick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: true,
    }
  },
  watch: {
    expandSectionToggle() {
      this.isExpanded = this.displayChevron ? true : true
    },
    collapseSectionToggle() {
      this.isExpanded = this.displayChevron ? false : true
    },
  },
  created() {
    this.isExpanded = this.displayChevron ? this.expandedByDefault : true
  },

  methods: {
    onHeaderClick(evt) {
      if (this.disableHeaderClick) {
        this.$emit("onHeaderClick", evt)
        this.isExpanded = this.displayChevron ? true : true
        return
      }

      /**
       * onHeaderClick event.
       *
       * @type {evt}
       */
      this.$emit("onHeaderClick", evt)
      this.isExpanded = this.displayChevron ? !this.isExpanded : true
    },
    onCollapseClick(evt) {
      /**
       * onCollapseClick event.
       *
       * @type {evt}
       */
      // console.log(evt)
      this.$emit("onCollapseClick", evt)
      this.isExpanded = this.displayChevron ? !this.isExpanded : true
    },
    onClick(evt) {
      /**
       * onSaveButtonClick event.
       *
       * @type {object}
       */
      this.$emit("onSaveButtonClick", evt)
    },
  },
}
